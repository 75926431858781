<template>
    <div class="vx-col md:w-1/1 w-full mb-base">
        <vs-table
            search 
            stripe 
            border 
            description 
            :sst="true" 
            :data="table.data" 
            :max-items="table.length"
            :total="table.total" 
            @search="handleSearch" 
            @change-page="handleChangePage" 
            @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>

            <template slot="thead">
                <vs-th sort-key="">Action</vs-th>
                <vs-th sort-key="">Module</vs-th>
                <vs-th sort-key="">Title</vs-th>
                <vs-th sort-key="">Value</vs-th>
                <vs-th sort-key="">Default Value</vs-th>
                <vs-th sort-key="">Warehouse</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex">
                            <vs-button
                                size="small"
                                color="warning"
                                icon-pack="feather"
                                icon="icon-edit"
                                title="Edit"
                                style="margin-right: 5px"
                                @click="handleEdit(tr.setting_id)"
                                :disabled="!tr.setting_id"
                            />
                        </div>
                    </vs-td>
                    <vs-td>{{ tr.module }}</vs-td>
                    <vs-td>{{ tr.title }}</vs-td>
                    <vs-td>{{ tr.value ? tr.value : tr.default_value }}</vs-td>
                    <vs-td>{{ tr.default_value }}</vs-td>
                    <vs-td>{{ tr.warehouse_code }} {{ tr.warehouse_name }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage"/>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        selectedModule: {
            type: String,
        },
        reset: {
            type: Number,
        }
    },
    data() {
        return {
            deleteId: null,
            module: null,
            table: this.tableDefaultState(),
        }
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        setStartEnd() {
            let valStart = this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }

            if (this.table.total == 0) {
                valStart = 0;
            }

            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (this.table.totalSearch < this.table.total && this.table.search != "") {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/setting/warehouse-configuration", {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        search: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        module: this.module,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.page = 1;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleEdit(id) {
            this.deleteId = id
        },
    },
    watch: {
        module(v) {
            this.module = v;
        },
        selectedModule(v) {
            if (v) {
                this.module = v;
                this.getData();
            } else if (!v) {
                this.module = null;
                this.getData();
            }
        },
        deleteId(v) {
            if (v) {
                this.$emit("selectedId", v);
            }
        },
        reset(v) {
            this.deleteId = null;
            this.module = null;
        }
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
}
</script>