<template>
    <div class="vx-col md:w-1/1 w-full mb-base">
        <vx-card :title="(this.settingId ? 'Edit' : 'Create') + ' Warehouse Configuration'">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <span>Module</span>
                    <multiselect 
                        class="selectExample" 
                        label="module"
                        name="module"
                        v-model="module" 
                        placeholder="Select module"
                        :options="optionsModule" 
                        track-by="module"
                        :multiple="false" 
                        :searchable="true"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <span>Title</span>
                    <multiselect 
                        class="selectExample" 
                        label="label"
                        name="label"
                        v-model="title" 
                        placeholder="Select title"
                        :options="optionsTitle" 
                        track-by="label"
                        :multiple="false" 
                        :searchable="true"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <span>Value</span>
                    <multiselect 
                        v-if="this.inputType === 'single-choice' && this.title !== null"
                        class="selectExample" 
                        id="value" 
                        v-model="value" 
                        placeholder="Select value"
                        :options="optionsValue"
                        :multiple="false"
                        :searchable="true"
                    />
                    <vs-input
                        v-if="this.inputType !== 'single-choice' || this.title === null"
                        type="number"
                        v-model="numValue"
                        style="width: auto;"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <span>Warehouse</span>
                    <multiselect 
                        class="selectExample" 
                        label="label"
                        name="label"
                        v-model="warehouse" 
                        placeholder="Select warehouse"
                        :options="optionsWarehouse" 
                        track-by="label"
                        :multiple="false" 
                        :searchable="true"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <span>Description</span>
                    <vs-textarea
                        class="w-full" 
                        id="description" 
                        name="description" 
                        v-model="description"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
                <div class="vx-col w-full">
                    <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
                    <vs-button :disabled="!this.settingId" type="border" color="danger" class="mr-3 mb-2" @click="handleReset">Reset</vs-button>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        id: {
            type: Number
        },
    },
    data() {
        return {
            settingId: null,
            module: null,
            optionsModule: [],
            title: null,
            optionsTitle: [],
            warehouse: null,
            optionsWarehouse: [],
            description: '',
            inputType: null,
            value: null,
            numValue: 0,
            rawOptValue: [],
            optionsValue: [],
            reset: 0,
        };
    },
    mounted() {
        this.getOptionModule();
        this.getWarehouses();
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http.get("/api/wms/v1/setting/warehouse-configuration/get-edit/" + this.settingId)
            .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.module = resp.data.module;
                    this.title = resp.data.title;
                    this.value = resp.data.value;
                    this.warehouse = resp.data.warehouse;
                    this.description = resp.data.description;
                    if (resp.data.input_type == 'number') {
                        this.numValue = parseInt(this.value)
                    }
                    this.$vs.loading.close();
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        getOptionTitle(mod) {
            this.$http.get("/api/wms/v1/setting/warehouse-configuration/get-title/" + mod)
            .then(resp => {
                if (resp.code == 200) {
                    this.optionsTitle = resp.data.records;
                } else {
                    this.optionsTitle = [];
                    console.log(resp.data)
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        getOptionValue(title) {
            this.$http.get("/api/wms/v1/setting/warehouse-configuration/get-value/" + title)
            .then(resp => {
                if (resp.code == 200) {
                    this.inputType = resp.data.input_type;
                    this.rawOptValue = resp.data.option_value;
                    this.joinTheArray();
                } else {
                    this.rawOptValue = [];
                    console.log(resp.data)
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        joinTheArray() {
            if (Array.isArray(this.rawOptValue) && typeof this.rawOptValue[0] === 'object') {
                this.rawOptValue.forEach(val => {
                    this.optionsValue.push(val.join(', '))
                });
            } else {
                this.rawOptValue.forEach(val => {
                    this.optionsValue.push(val)
                });
            }
        },
        getOptionModule() {
            this.$http.get("/api/wms/v1/setting/warehouse-configuration/module-option", {
                params: {
                    order: "module",
                    sort: "asc"
                }
            })
            .then(resp => {
                if (resp.code == 200) {
                    this.optionsModule = resp.data.records;
                } else {
                    this.optionsModule = []
                    console.log(resp.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        getWarehouses() {
            this.$http.get("/api/wms/v1/setting/warehouse-configuration/get-warehouses")
            .then(resp => {
                if (resp.code == 200) {
                    this.optionsWarehouse = resp.data.records;
                } else {
                    this.optionsWarehouse = []
                    console.log(resp.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        handleSubmit() {
            if (!this.module) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Module is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.title) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Title of module is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (this.inputType === 'single-choice') {
                if (!this.value) {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Please choose the value",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return false;
                }
            }

            if (this.inputType !== 'single-choice') {
                if (this.numValue < 1) {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "The value must be higher than zero",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return false;
                }
            }

            let formData = new FormData();
            formData.append("module", this.module.module)
            formData.append("title", this.title.label)
            formData.append("warehouse_id", this.warehouse ? this.warehouse.id : 0)
            formData.append("value", this.value)
            formData.append("numValue", this.numValue)
            formData.append("description", this.description)

            let http = null
            if (this.settingId) {
                http = this.$http.put("/api/wms/v1/setting/warehouse-configuration/update/" + this.settingId, formData)
            } else {
                http = this.$http.post("/api/wms/v1/setting/warehouse-configuration/create", formData)
            }

            this.$vs.loading();
            http.then((resp) => {
                if (resp.code == 200) {
                    if (this.settingId) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "The data was successfully updated",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "The data was successfully created",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                    this.$vs.loading.close();
                    this.handleReset();
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    this.$vs.loading.close();
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        handleReset() {
            this.$vs.loading();
            this.settingId = null
            this.module = null
            this.title = null
            this.value = null
            this.warehouse = null
            this.description = null
            this.optionsTitle = []
            this.rawOptValue = []
            this.optionsValue = []
            this.reset++
            this.$emit("reset", this.reset)
            this.$vs.loading.close();
        }
    },
    watch: {
        id(v) {
            this.settingId = v;
            this.getData();
        },
        module(v) {
            if (v && this.settingId) {
                this.optionsTitle = [];
                this.getOptionTitle(v.module);
            } else if (v && !this.settingId) {
                this.$emit("module", v.module);
                this.rawOptValue = [];
                this.optionsValue = [];
                this.getOptionTitle(v.module);
                this.title = null;
                this.value = null;
                this.numValue = 0;
            } else if (!v && !this.settingId) {
                this.$emit("module", null);
                this.title = null;
                this.optionsTitle = [];
                this.value = null;
                this.rawOptValue = [];
                this.optionsValue = [];
                this.numValue = 0;
            }
        },
        title(v) {
            if (v && this.settingId) {
                this.rawOptValue = [];
                this.optionsValue = [];
                this.getOptionValue(v.label);
            } else if (v && !this.settingId) {
                this.value = null;
                this.rawOptValue = [];
                this.optionsValue = [];
                this.numValue = 0;
                this.getOptionValue(v.label);
            } else if (!v && !this.settingId || !v && this.settingId) {
                this.value = null;
                this.rawOptValue = [];
                this.optionsValue = [];
                this.numValue = 0;
            }
        }
    },
};
</script>