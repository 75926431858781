<template>
    <vx-card title="Setting - Warehouse Configuration">
        <div class="vx-input-group flex">
            <div class="vx-row mt-3" style="width:100%">
                <div class="vx-col sm:w-2/3 w-full flex">
                    <Table 
                        :reset="reset"
                        :selectedModule="selectedModule"
                        @selectedId="(val) => {this.selectedId = val}"
                    ></Table>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <Form 
                        :id="selectedId"
                        @module="(val) => {this.selectedModule = val}"
                        @reset="(val) => {this.reset = val}"
                    ></Form>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import Table from "./Table.vue";
import Form from "./Form.vue";
export default {
    components: {
        Table,
        Form
    },
    data() {
        return {
            selectedId: 0,
            selectedModule: null,
            reset: 0,
        }
    },
    methods: {
        // ...
    },
    watch: {
        selectedId(val) {
            if (val) {
                console.log("dapat id dari table => ", val)
            }
        },
        reset(v) {
            this.selectedId = 0;
            this.selectedModule = null;
        }
    }
}
</script>